import React from "react";
import { Link } from "gatsby";
import { Col, Container, Row } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import Residentialhero from "../components/hero/Residentialhero";
import Layout from "../components/Layout";
import CTA4 from "../components/CTA4";

function RHI() {
  return (
    <Layout>
      <Residentialhero />
      <Container>
        <div id="tabs-container">
          <div id="sidebar">
            <ul>
              <li className="sidebar-text">
                <Link href="#Pre-Purchase Inspection">
                  Pre-Purchase Inspections
                </Link>
              </li>
              <li className="sidebar-text">
                <Link href="#Pre-Sale Inspection">Pre-Sales Inspections</Link>
              </li>
              <li className="sidebar-text">
                <Link href="#Home Maintenance Inspection">
                  Maintenance Inspections
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h3>Residential Inspections</h3>
          <p>
            Our team has been providing peace of mind through quality
            residential inspections in Tampa and all the surrounding areas for
            over 30 years. Our home inspectors provide superior Pre-Purchase
            Inspections, Pre-Sales Inspections, and Home Maintenance
            Inspections. Do not be led astray by fly-by-night companies home
            inspectors with very little experience. Trust our experts to provide
            not just quality inspection reports but also the experience to know
            what to look for.
          </p>
        </div>
        <h3 id="Pre-Purchase Inspection">Pre-Purchase Inspection</h3>
        <p>
          Without a doubt, purchasing a home is one of the most exciting times
          in most people’s lives. It's very likely that this real estate
          transaction will be the single biggest investment that most people
          will ever make in their lives. hat is why it is very important to do
          your due diligence and to know exactly what you’re investing your hard
          earned money into, to make sure you are making a wise investment and
          also you will want to make sure the roof you are putting over your
          families head is a safe one!
        </p>
        <p>
          If you are making a home purchase, getting a Professional Home
          Inspection is the first step in making sure your dream home is in good
          condition and does not become a nightmare or a money pit! Most of the
          time, general home inspections uncover little things that need
          correcting, but sometimes those little things can add up quickly to a
          sizable amount of money and you need to be aware of these little
          things because if not taken care of can turn into big things. And then
          sometimes they might not be so little, like a roof that is prematurely
          at the end of it’s life span and is already leaking or main plumbing
          lines that have never been updated and are clogged and causing
          backups! Getting a Pre-Purchase Home Inspection by Young Home
          Inspections virtually eliminates any worry you may have of any
          existing issues in the home you’re wanting to purchase! Our home
          inspection report will also tell you, what to budget for in the future
          as far as general upkeep of the home and when replacements of major
          components will be necessary! In other word’s how old and in what
          condition are the major components in the home and how much longer
          will they last?
        </p>
        <p>
          Remember that the professional home inspector is the individual that a
          home buyer pays for their objectivity, opinions, and expertise about
          the potential problems and potential issues with the condition of the
          home in question. The job of the home inspection company is to give
          you the most comprehensive, honest, and unbiased view about the home.
          They have your best interests in mind and want to make sure you can
          see the whole picture, not just the snapshot people with vested
          interests wants you to see. We are on your side! You and your family
          are our priority! We are State licensed, InterNachi Certified,
          continually educated and insured to do just this, protect you, your
          family and your investment!
        </p>
      </Container>
      <Container>
        <div>
          <div className="img-right">
            <StaticImage src="../images/prepurchase.jpg" />
          </div>
          <p>
            InterNACHI standards are very strict and require adherence to their
            code of ethics. Their standards are among the highest quality and
            even exceed the ASHI standards of practice.
          </p>
          <p>
            The truth is, that while our company's standards and code of ethics
            have been guided by both InterNACHI and ASHI. Our own personal
            standards exceed both InterNACHI and ASHI standards of practice.
          </p>
        </div>
        <p>
          At Young Home Inspections our 320 Point Pre-Purchase Inspections
          include:
        </p>
        <ul>
          <li>
            Roof: Covering to include, asphalt shingle, tile, metal or membrane,
            roof decking, vents, eaves, soffits, fascia, flashing, chimney,
            gutters and downspouts
          </li>
          <li>
            Attic, Trusses, ceiling joists, insulation, ventilation, fire
            blocking, vent stacks
          </li>
          <li>
            Structural: Walls, floors, floor joists, sub floors, ceiling joists,
            trusses, decks, stairs, railings, support beams, pier foundations
          </li>
          <li>
            Electrical: Service panel, main disconnect, breakers, outlets,
            switches, GFCI outlets, fixtures
          </li>
          <li>
            Plumbing: Fixtures, water pressure, water meter, water heater,
            visible drain lines, visible supply lines
          </li>
          <li>
            HVAC system: Air compressor, handler, ductwork, temperature
            differential, and age
          </li>
          <li>
            Exterior: Paint, siding, walls, doors, windows, decks, birdcages,
            screened enclosures, pool equipment, spas, outdoor kitchens, docks,
            seawalls, boatlifts, irrigation, crawlspaces
          </li>
          <li>
            Interior: Walls, floors, ceilings, doors, appliances, cabinets,
            countertops, stairs, railings
          </li>
          <li>Grading, overall property drainage</li>
          <li>
            Rain Protection: Gutters, downspouts, grading, overall property
            drainage
          </li>
          <li>
            Surrounding Components: Driveways, walkways, retaining walls,
            stoops, porches, railings, patios
          </li>
          <li>
            Garage: Garage door, garage interior, electronic opener, safety
            sensors exterior deck
          </li>
          <li>Fireplace: Damper operation, exposed flue and hearth</li>
        </ul>
        <br />
        <p>Other Services Offered:</p>
        <ul>
          <li>Termite Inspections</li>
          <li>Mold Inspections</li>
          <li>Well & Septic Inspections</li>
        </ul>
        <Row>
          <Link to="/request" className="btn btn-secondary mb-50">
            <p>Schedule Your Inspection Now</p>
          </Link>
        </Row>
      </Container>
      <Container>
        <Row className="cert-banner mb-100">
          <Col>
            <a href="https://www.nachi.org/">
              <StaticImage
                src="../images/internachi-certified.png"
                alt="Internachi certified"
                target="_blank"
              />
            </a>
          </Col>
          <Col>
            <a href="https://www.angi.com/companylist/us/fl/new-port-richey/young-home-inspections-reviews-153513.htm">
              <StaticImage
                src="../images/Angi_SSA_2020_HighRes.jpeg"
                alt="Angies List"
                target="_blank"
              />
            </a>
          </Col>
          <Col>
            <a href="https://fabi.org/">
              <StaticImage
                src="../images/fabi-rpi.png"
                alt="FABI"
                target="_blank"
              />
            </a>
          </Col>
          <Col>
            <a href="https://www.nachi.org/certified-inspectors/brian-perry-cbc1251244-hi5282-43730">
              <StaticImage
                src="../images/internachi-cpi.png"
                alt="Internachi CPI"
                target="_blank"
              />
            </a>
          </Col>
        </Row>
      </Container>
      <Container>
        <h3 id="Pre-Sale Inspection">Pre-Sale Inspection</h3>
        <p>
          If you want to make sure the sale of your home is as painless and
          quick as can be, then a Pre-Sale Home Inspection is right for you.
          Much like a Pre-Purchase Home inspection, a Pre-Sale Home Inspection
          covers the same checklist, but its seller initiated before the home is
          even listed for sale. This really helps the process go smoothly
          because the seller will be able to make sure there is nothing wrong or
          have the chance to repair any issues found. Then when it comes time
          for the buyers to have the home inspected themselves, you’ll know
          exactly what their home inspection report will contain. Being prepared
          and knowing that buyers inspection report looks great gives you a
          reassurance and makes the whole experience much easier.
        </p>
        <p>
          There are many reasons a seller would want to obtain a Pre-Sale
          Inspection. A lot of times homeowners are unaware of the value or any
          issues with their home. It helps to get a professional, non-biased
          third party’s opinion about the condition of your home. Many times,
          there are safety concerns that need to be addressed before letting
          realtors show your home to potential buyers. Getting a Pre-Sale
          inspection from a licensed home inspector almost always reduces or
          eliminates any fears or suspicions for most interested buyers. One of
          the biggest reasons to have your home inspected before listing it for
          sale is to completely eliminate the use of repairs as a bargaining and
          negotiating tool that potential buyers can use against you. If you
          already know about all the issues of your home and have addressed them
          properly, then there is nothing to worry about. It also gives you time
          to either make any needed repairs on your own or it lets you take your
          time to shop around and get the best possible pricing. It also helps
          you price your home accordingly. And lets you decide what you want to
          fix or disclose what you do not. Making this a very trustworthy,
          transparent transaction for both you and your buyer.
        </p>
        <p>All Pre-Sale Home Inspections include:</p>
        <ul>
          <li>
            Roof: Covering to include, asphalt shingle, tile, metal or membrane,
            roof decking, vents, eaves, soffits, fascia, flashing, chimney,
            gutters and downspouts
          </li>
          <li>
            Attic, Trusses, ceiling joists, insulation, ventilation, fire
            blocking, vent stacks
          </li>
          <li>
            Structural: Walls, floors, floor joists, sub floors, ceiling joists,
            trusses, decks, stairs, railings, support beams, pier foundations
          </li>
          <li>
            Electrical: Service panel, main disconnect, breakers, outlets,
            switches, GFCI outlets, fixtures
          </li>
          <li>
            Plumbing: Fixtures, water pressure, water meter, water heater,
            visible drain lines, visible supply lines
          </li>
          <li>
            HVAC system: Air compressor, handler, ductwork, temperature
            differential, and age
          </li>
          <li>
            Exterior: Paint, siding, walls, doors, windows, decks, birdcages,
            screened enclosures, pool equipment, spas, outdoor kitchens, docks,
            seawalls, boatlifts, irrigation, crawlspaces
          </li>
          <li>
            Interior: Walls, floors, ceilings, doors, appliances, cabinets,
            countertops, stairs, railings
          </li>
          <li>Grading, overall property drainage</li>
          <li>
            Rain Protection: Gutters, downspouts, grading, overall property
            drainage
          </li>
          <li>
            Surrounding Components: Driveways, walkways, retaining walls,
            stoops, porches, railings, patios
          </li>
          <li>
            Garage: Garage door, garage interior, electronic opener, safety
            sensors exterior deck
          </li>
          <li>Fireplace: Damper operation, exposed flue and hearth</li>
        </ul>
        <div className="img-right">
          <StaticImage src="../images/presale.jpg" />
        </div>
        <Row>
          <Link to="/request" className="btn btn-secondary mb-50">
            <p>Schedule Your Inspection Today</p>
          </Link>
        </Row>
      </Container>
      <Container>
        <h3 id="Home Maintenance Inspection">Home Maintenance Inspection</h3>
        <p>
          This inspection is for the concerned home owner that wants to know the
          present condition of their home and it’s components. If there are any
          hazards or deficiencies that need immediate attention? They also want
          to know what is in need of repair now what to budget for in the
          future.
        </p>
        <ul>
          <li>
            Roof: Covering to include, asphalt shingle, tile, metal or membrane,
            roof decking, vents, eaves, soffits, fascia, flashing, chimney,
            gutters and downspouts
          </li>
          <li>
            Attic, Trusses, ceiling joists, insulation, ventilation, fire
            blocking, vent stacks
          </li>
          <li>
            Structural: Walls, floors, floor joists, sub floors, ceiling joists,
            trusses, decks, stairs, railings, support beams, pier foundations
          </li>
          <li>
            Electrical: Service panel, main disconnect, breakers, outlets,
            switches, GFCI outlets, fixtures
          </li>
          <li>
            Plumbing: Fixtures, water pressure, water meter, water heater,
            visible drain lines, visible supply lines
          </li>
          <li>
            HVAC system: Air compressor, handler, ductwork, temperature
            differential, and age
          </li>
          <li>
            Exterior: Paint, siding, walls, doors, windows, decks, birdcages,
            screened enclosures, pool equipment, spas, outdoor kitchens, docks,
            seawalls, boatlifts, irrigation, crawlspaces
          </li>
          <li>
            Interior: Walls, floors, ceilings, doors, appliances, cabinets,
            countertops, stairs, railings
          </li>
          <li>Grading, overall property drainage</li>
          <li>
            Rain Protection: Gutters, downspouts, grading, overall property
            drainage
          </li>
          <li>
            Surrounding Components: Driveways, walkways, retaining walls,
            stoops, porches, railings, patios
          </li>
          <li>
            Garage: Garage door, garage interior, electronic opener, safety
            sensors exterior deck
          </li>
          <li>Fireplace: Damper operation, exposed flue and hearth</li>
        </ul>
      </Container>
      <CTA4 />
    </Layout>
  );
}

export default RHI;
